import React from "react";
import PropTypes from "prop-types";

import { Row, Col } from "react-bootstrap";

import ServiceItem from "components/ServiceItem";
import SectionHeader from "components/SectionHeader";
import PageSection from "components/PageSection";

const Goals = ({ className, frontmatter }) => {
  if (!frontmatter) {
    return null;
  }

  const { anchor, header: rootHeader, subheader: rootSubHeader, content: rootContent, goals } = frontmatter;

  return (
    <PageSection className={className} id={anchor}>
      <Row>
        <SectionHeader header={rootHeader} subheader={rootSubHeader} content={rootContent} />
      </Row>
      <Row className="text-center">
        {goals.map(
          ({key, content, imageFileName}) => (
          <Col md={4} key={key}>
            <ServiceItem
              imageFileName={imageFileName}
              content={content}
            />
          </Col>
        ))}
      </Row>
    </PageSection>
  );
};

Goals.propTypes = {
  className: PropTypes.string,
  frontmatter: PropTypes.object,
};

Goals.defaultProps = {
  className: null,
  frontmatter: null,
};

export default Goals;
